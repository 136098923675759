import * as React from "react";
import * as styles from "./styles/contact.module.css";
import classnames from "classnames/bind";
import Hero from "../components/HeroSection/Hero";
import Intro from "../components/IntroSection/Intro";
import Card from "../components/Card/Card";
import Footer from "../components/Footer/Footer";
import daphnePic from "../images/daphne.jpg";
import Container from "../components/Container/Container";
import ContactForm from "../components/ContactForm/ContactForm";

const cx = classnames.bind(styles);

const Contact = () => {
  console.log(styles);
  return (
    <main className={cx("base")}>
      <title>Yirpa Ministries | Pricing</title>
      <Hero mini />
      <Intro title={"Contact"} style={{ paddingBottom: "7rem" }}>
        Yirpa is een Christelijke praktijk die natuurlijke behandelingen
        gebruikt om herstel in het lichaam te bevorderen en tot stand te
        brengen.
      </Intro>

      <div className={cx("introCards")}>
        <Card>
          <Container flex>
            <img src={daphnePic} />
            <div>
              <h2>Neem contact op</h2>
              <p>
                <strong>www.yirpaministry.com</strong>
              </p>
              <p>
                Email:{" "}
                <strong>
                  <a href="mailto:info@yirpaministry.com">
                    info@yirpaministry.com
                  </a>
                </strong>
              </p>
              <p>
                Tel: <strong>0628021544</strong>
              </p>
              <p>
                Adres:{" "}
                <strong>
                  Weerdingerkanaal Zuidzijde 129, 7831 AH, Nieuw-Weerdinge
                </strong>
              </p>
              <p>
                Kvk: <strong>74228315</strong>
              </p>
              <p>
                Rekeningnummer: <strong>NL13KNAB0258762012</strong>
              </p>
            </div>
          </Container>
        </Card>
      </div>

      <ContactForm />

      <Footer />
    </main>
  );
};

export default Contact;
